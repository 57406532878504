import React from 'react';
import Seo from '../components/config/Seo';
import { graphql } from 'gatsby';
import { Typography, Box } from '@material-ui/core';
import marked from 'marked';
import Container from '../components/shared/Container';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(
  ({ palette: { secondary, text }, spacing }) => ({
    container: {
      padding: spacing(20, 0),
      color: text.primary,
      backgroundColor: secondary.main,
      whiteSpace: 'pre-line',
      '& a': {
        color: text.primary,
        textDecoration: 'none',
        position: 'relative',
      },
      '& ul': {
        padding: spacing(0, 0, 0, 5),
        marginTop: spacing(-3),
      },
    },
  })
);

const CookiePolicy = ({ data }) => {
  const classes = useStyles();
  return (
    <>
      <Seo title="Cookie Policy" />
      <Box className={classes.container}>
        <Container>
          <Typography
            dangerouslySetInnerHTML={{
              __html: marked(
                data.allContentfulCookiePolicy.edges[0].node
                  .policy.policy
              ).replace(/_/g, ' '),
            }}
          />
          {/* For some reason the word 'Cookie Policy' seems to be banned
           * when displaying data from Contentful, so to work around this
           * we write 'Cookie_Policy' in Contentful and then remove the underscore.*/}
        </Container>
      </Box>
    </>
  );
};

export const query = graphql`
  query CookiePolicyPage {
    allContentfulCookiePolicy(
      sort: { fields: id, order: ASC }
      limit: 1
    ) {
      edges {
        node {
          policy {
            policy
          }
        }
      }
    }
  }
`;

export default CookiePolicy;
